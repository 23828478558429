import * as React from "react";
import { useState } from "react";
import "@fontsource/roboto-mono";
import "@fontsource/bebas-neue";
import "@fontsource/roboto";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Fannybag from "../images/product/fannybag/default2.jpg";
import Antitheftbackpak from "../images/product/Anti theft backpack/default.jpg";
import DianeBackpak from "../images/product/Dianne Backpack/default.jpg";
import Kafabackpack from "../images/product/Kafa Backpack/default.jpg";
import Foldoverbackpack from "../images/product/Foldover backpack/default.jpg";
import HBackpack from "../images/product/H Backpack/default.jpg";
import Shanellobackpack from "../images/product/Shanello Backpack/default.jpg";
import EasyBag from "../images/product/Easy Bag/default.jpg";
import OrigamiBag from "../images/product/Origami Bag/default.jpg";
import RummyBag from "../images/product/Rummy Bag/default.jpg";
import LaptopBag from "../images/product/Laptop Bag/default.jpg";
import SoniaBag from "../images/product/Sonia Bag/default.jpg";
import JuneraBag from "../images/product/Junera Bag/default.jpg";
import DrowstringBag from "../images/product/Drowstring Bag/default.jpg";
import ResiBag from "../images/product/Resi Bag/default.jpg";
import DoctorBag from "../images/product/Doctor Bag/default.jpg";
import KiaBag from "../images/product/Kia Bag/default.jpg";
import BiggerBag from "../images/product/Bigger Bag/default.jpg";
import AlineaBag from "../images/product/Alinea bag/default.jpg";
import QuininaBag from "../images/product/Quinina bag/default.jpg";
import HammockBag from "../images/product/Hammock bag/default.jpg";
import FaridaBag from "../images/product/Farida Slingbag/default.jpg";
import ConnieBag from "../images/product/Connie Slingbag/default.jpg";
import GarberaBag from "../images/product/Garbera Slingbag/default.jpg";
import ReninaBag from "../images/product/Renina Slingbag/default.jpg";
import ReikoBag from "../images/product/Reiko Slingbag/default.jpg";
import LinsyBag from "../images/product/Linsy Slingbag/default.jpg";
import LilyPouch from "../images/product/Lily pouch/default.jpg";
import ClematisPouch from "../images/product/Clematis pouch/default.jpg";
import TissuePouch from "../images/product/Tissue Pouch/default.jpg";
import PouchUnik from "../images/product/Pouch unik/default.jpg";
import SmartphoneHolder from "../images/product/Smartphone holder/default.jpg";
import DoubleRits from "../images/product/Double rits tissue pouch/default.jpg";
import DompetMotif from "../images/product/Dompet motif/default.jpg";
import DompetEtnik from "../images/product/Dompet Etnik/default.jpg";
import HybridBackpack from "../images/product/Hybrid Backpack/default.jpg";
import MarbleBackpack from "../images/product/Marble Backpack/default.jpg";

import { logProduk } from "../log";

export default function ProductList({ page }) {
  const [produkarray] = useState([
    {
      nama: "Hybrid Backpack",
      url: "/hybridbackpack",
      foto: HybridBackpack,
      harga: "Rp 320,000",
      diplayprice: "Rp 240,000",
      disc: "25%",
    },
    {
      nama: "Marble Backpack",
      url: "/marblebackpack",
      foto: MarbleBackpack,
      harga: "Rp 350,000",
      diplayprice: "Rp 245,000",
      disc: "30%",
    },
    {
      nama: "Dompet Etnik",
      url: "/dompetetnik",
      foto: DompetEtnik,
      harga: "Rp 175,000",
      diplayprice: "Rp 52,500",
      disc: "70%",
    },
    {
      nama: "Dompet motif",
      url: "/dompetmotif",
      foto: DompetMotif,
      harga: "Rp 150,000",
      diplayprice: "Rp 82,500",
      disc: "45%",
    },
    {
      nama: "Double rits tissue pouch",
      url: "/doublerits",
      foto: DoubleRits,
      harga: "Rp 50,000",
      diplayprice: "Rp 27,500",
      disc: "45%",
    },
    {
      nama: "Pouch unik",
      url: "/pouchunik",
      foto: PouchUnik,
      harga: "Rp 45,000",
      diplayprice: "Rp 20,250",
      disc: "55%",
    },
    {
      nama: "Smartphone holder",
      url: "/smartphoneholder",
      foto: SmartphoneHolder,
      harga: "Rp 175,000",
      diplayprice: "Rp 96,250",
      disc: "45%",
    },
    {
      nama: "Clematis Pouch",
      url: "/clematispouch",
      foto: ClematisPouch,
      harga: "Rp 75,000",
      diplayprice: "Rp 33,750",
      disc: "55%",
    },
    {
      nama: "Tissue Pouch",
      url: "/tissuepouch",
      foto: TissuePouch,
      harga: "Rp 45,000",
      diplayprice: "Rp 20,250",
      disc: "55%",
    },
    {
      nama: "Lily Pouch",
      url: "/lilybag",
      foto: LilyPouch,
      harga: "Rp 65,000",
      diplayprice: "Rp 35,750",
      disc: "45%",
    },
    {
      nama: "Reiko Slingbag",
      url: "/reikobag",
      foto: ReikoBag,
      harga: "Rp 275,000",
      diplayprice: "Rp 137,500",
      disc: "50%",
    },
    {
      nama: "Linsy Slingbag",
      url: "/linsybag",
      foto: LinsyBag,
      harga: "Rp 275,000",
      diplayprice: "Rp 192,500",
      disc: "30%",
    },
    {
      nama: "Garbera Slingbag",
      url: "/garberabag",
      foto: GarberaBag,
      harga: "Rp 175,000",
      diplayprice: "Rp 87,500",
      disc: "50%",
    },
    {
      nama: "Renina Slingbag",
      url: "/reninabag",
      foto: ReninaBag,
      harga: "Rp 225,000",
      diplayprice: "Rp 112,500",
      disc: "50%",
    },
    {
      nama: "Farida Slingbag",
      url: "/faridabag",
      foto: FaridaBag,
      harga: "Rp 175,000",
      diplayprice: "Rp 105,000",
      disc: "40%",
    },
    {
      nama: "Connie Slingbag",
      url: "/conniebag",
      foto: ConnieBag,
      harga: "Rp 225,000",
      diplayprice: "Rp 157,500",
      disc: "30%",
    },
    {
      nama: "Hammock bag",
      url: "/hammockbag",
      foto: HammockBag,
      harga: "Rp 315,000",
      diplayprice: "Rp 242,550",
      disc: "23%",
    },
    {
      nama: "Quinina bag",
      url: "/quininabag",
      foto: QuininaBag,
      harga: "Rp 350,000",
      diplayprice: "Rp 276,500",
      disc: "21%",
    },
    {
      nama: "Alinea bag",
      url: "/alineabag",
      foto: AlineaBag,
      harga: "Rp 350,000",
      diplayprice: "Rp 280,000",
      disc: "20%",
    },
    {
      nama: "Bigger Bag",
      url: "/biggerbag",
      foto: BiggerBag,
      harga: "Rp 215,000",
      diplayprice: "Rp 118,250",
      disc: "45%",
    },
    {
      nama: "Kia Bag",
      url: "/kiabag",
      foto: KiaBag,
      harga: "Rp 115,000",
      diplayprice: "Rp 63,250",
      disc: "45%",
    },
    {
      nama: "Resi Bag",
      url: "/resibag",
      foto: ResiBag,
      harga: "Rp 150,000",
      diplayprice: "Rp 82,500",
      disc: "45%",
    },
    {
      nama: "Doctor Bag",
      url: "/doctorbag",
      foto: DoctorBag,
      harga: "Rp 230,000",
      diplayprice: "Rp 161,000",
      disc: "30%",
    },
    {
      nama: "Drowstring Bag",
      url: "/drowstringbag",
      foto: DrowstringBag,
      harga: "Rp 150,000",
      diplayprice: "Rp 90,000",
      disc: "40%",
    },
    {
      nama: "Junera Bag",
      url: "/junerabag",
      foto: JuneraBag,
      harga: "Rp 230,000",
      diplayprice: "Rp 193,200",
      disc: "16%",
    },
    {
      nama: "Sonia Bag",
      url: "/soniabag",
      foto: SoniaBag,
      harga: "Rp 350,000",
      diplayprice: "Rp 245,000",
      disc: "30%",
    },
    {
      nama: "Laptop Bag",
      url: "/laptopbag",
      foto: LaptopBag,
      harga: "Rp 250,000",
      diplayprice: "Rp 212,500",
      disc: "15%",
    },
    {
      nama: "Rummy Bag",
      url: "/rummybag",
      foto: RummyBag,
      harga: "Rp 150,000",
      diplayprice: "Rp 90,000",
      disc: "40%",
    },
    {
      nama: "Origami Bag",
      url: "/origamibag",
      foto: OrigamiBag,
      harga: "Rp 115,000",
      diplayprice: "Rp 63,250",
      disc: "45%",
    },
    {
      nama: "Easy Bag",
      url: "/easybag",
      foto: EasyBag,
      harga: "Rp 115,000",
      diplayprice: "Rp 63,250",
      disc: "45%",
    },
    {
      nama: "Foldover backpack",
      url: "/foldoverbackpack",
      foto: Foldoverbackpack,
      harga: "Rp 175,000",
      diplayprice: "Rp 52,500",
      disc: "70%",
    },
    {
      nama: "H! Backpack",
      url: "/hbackpack",
      foto: HBackpack,
      harga: "Rp 375,000",
      diplayprice: "Rp 281,250",
      disc: "25%",
    },
    {
      nama: "Shanello Backpack",
      url: "/shanellobackpack",
      foto: Shanellobackpack,
      harga: "Rp 375,000",
      diplayprice: "Rp 281,250",
      disc: "25%",
    },
    {
      nama: "Fanny Bag",
      url: "/fannybag",
      foto: Fannybag,
      harga: "Rp 175,000",
      diplayprice: "Rp 35,000",
      disc: "80%",
    },
    {
      nama: "Anti theft backpack",
      url: "/antitheftbackpak",
      foto: Antitheftbackpak,
      harga: "Rp 275,000",
      diplayprice: "Rp 192,500",
      disc: "30%",
    },
    {
      nama: "Dianne Backpack",
      url: "/dianne",
      foto: DianeBackpak,
      harga: "Rp 350,000",
      diplayprice: "Rp 192,500",
      disc: "45%",
    },
    {
      nama: "Kafa Backpack",
      url: "/kafa",
      foto: Kafabackpack,
      harga: "Rp 350,000",
      diplayprice: "Rp 245,000",
      disc: "30%",
    },
  ]);
  return (
    <>
      <Box sx={{ m: "0 auto", maxWidth: "1200px", p: 1, mt: 7 }}>
        <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
          {produkarray &&
            produkarray.map((produk, indexed) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                align="left"
                sx={{ mb: 2 }}
                key={indexed}
              >
                <Box
                  sx={{
                    bgcolor: "#FFFFFF",
                    fontSize: "15px",
                    borderRadius: 3,
                    boxShadow: 1,
                  }}
                  className="box"
                >
                  <div className="ribbon ribbon-top-left">
                    <span>DISKON {produk.disc}</span>
                  </div>
                  <Box
                    component="img"
                    sx={{
                      width: "100%",
                    }}
                    src={produk.foto}
                  />
                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    <Typography variant="h6" className="produkName">
                      <b>{produk.nama}</b>
                    </Typography>
                    <Typography variant="h6" className="pricingold">
                      {produk.harga}
                    </Typography>
                    <Typography variant="h6" className="pricing">
                      {produk.diplayprice}
                    </Typography>

                    <Button
                      variant="contained"
                      onClick={() => logProduk(page, produk.nama)}
                      href={produk.url}
                      sx={{
                        width: "100%",
                        border: "none",
                        p: 1,
                        mt: 3,
                      }}
                    >
                      Lihat Produk
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
}
