import * as React from "react";
import Footer from "../pages/footer";
import { useState } from "react";
import "@fontsource/roboto-mono";
import "@fontsource/bebas-neue";
import "@fontsource/roboto";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Slider from "react-slick";
import WebIcon from "../images/icon.png";
import ProductList from "../pages/productList";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Divider from "@mui/material/Divider";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Img1 from "../images/product/Foldover backpack/default.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { handleSubmit } from "../utils";
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
export default function WelcomePage() {
  const [produkinfo] = useState({
        nama: "Foldover backpack",
        harga:"Rp 175,000",diplayprice:"Rp 52,500",disc:"70%%",limit:"1",
   });
   const [Produkimg] = useState([
    {img:Img1}
   ]);
  const [Webdata, setWebdata] = useState({
    nama: "",
    whatsapp: "",
    alamat: "",
    permintaan: "",
    fotoreferensi: "",
    qty: "",
    produk: produkinfo.nama,
  });
  const settings = {
    dots: false,
    vertical: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  const handleChange = (event) => {
    setWebdata({
      ...Webdata,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangefoto = (event) => {
    setWebdata({
      ...Webdata,
      [event.target.name]: event.target.files[0],
    });
  };
  return (
    <>
      <Box sx={{ bgcolor: "rgba(255,255,255,0.6)" }}>
        <Box align="center">
          <br />
          <br />
          <Link href="/">
            <Box
              component="img"
              sx={{
                maxWidth: 400,
              }}
              alt="GarapIn x Rerejuna Craft"
              title="GarapIn x Rerejuna Craft"
              src={WebIcon}
            />
          </Link>
          <Box
            sx={{
              m: "0 auto",
              maxWidth: "800px",
              mt: 4,
            }}
          >
            <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} align="left">
                <Box className="immages slider ima2">
                  <Slider {...settings}>
                    {Produkimg && Produkimg.map((produktu, indexed) => (
                    <div key={indexed}>
                      <div className="ribbon ribbon-top-left">
                        <span>DISKON {produkinfo.disc}</span>
                      </div>
                      <img
                        src={produktu.img}
                        alt="GarapIn x Rerejuna Craft"
                        title="GarapIn x Rerejuna Craft"
                      />
                    </div>
                    ))}
                  </Slider>
                </Box>
                <Box className="slider-nav">
                {Produkimg && Produkimg.map((produkdu, indexedtu) => (
                  <div key={indexedtu}>
                    <img
                      src={produkdu.img}
                      alt="GarapIn x Rerejuna Craft"
                      title="GarapIn x Rerejuna Craft"
                    />
                  </div>
                 ))}
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                align="left"
                sx={{ mb: 2 }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography variant="h4" sx={{ fontSize: "1.5rem" }}>
                    <b>{produkinfo.nama}</b>
                  </Typography>
                  <Typography variant="h6" className="pricingold" sx={{pl:0,mt:2,fontFamily: "Roboto"}}>
                  {produkinfo.harga}
                  </Typography>
                  <Box
                    className="pricing2"
                    sx={{ fontSize: "20px",fontFamily: "Roboto" }}
                  >
                    {produkinfo.diplayprice}
                  </Box>
                  <Box
                    className="order"
                    sx={{ mt: "7px", fontFamily: "Roboto" }}
                  >
                    <ShoppingCartIcon /> {Math.floor(Math.random() * 5000) + 1000 } dilihat
                  </Box>
                  <Box
                    className="order"
                    sx={{ mt: "7px", fontFamily: "Roboto" }}
                  >
                    <ShoppingBasketIcon /> Minimal pemesanan {produkinfo.limit} pcs
                  </Box>
                  <Box
                    className="order"
                    sx={{ mt: "7px", fontFamily: "Roboto" }}
                  >
                    <Inventory2RoundedIcon /> Dimensi : P =  25 cm, T =  20 cm, L =  1 cm
                  </Box>
                  <Box
                    sx={{ mt: "25px", fontSize: "16px", fontFamily: "Roboto" }}
                  >
                    Foldover backpack memiliki model yang lebih santai namun tetap memiliki muatan yang banyak. Tas ini memiliki bagian yang dilipat pada bagian atasnya. Dibuat secara eksklusif sesuai dengan desain yang kamu inginkan.
<br/><br/>
Seluruh model tas Rerejuna dapat dipadukan dengan kain etnik khas Kalimantan Barat. Perpaduan backpack dan kain etnik membuat tampilan semakin stylish dan juga eksklusif.
<br/><br/>
Tentukan desain tasmu sendiri, dan Rerejuna siap mewujudkannya. Pesan sekarang dan dapatkan harga terbaik!.

<Box sx={{fontSize:12,mt:2}}>
<b>S & K</b>
<ol style={{textDecoration:'none',paddingLeft:10}}>
<li>Prioder Promo {produkinfo.nama}</li>
<li>Dikon berlaku untuk {produkinfo.limit} pembeli pertama</li>
<li>Diskon tidak berlaku untuk kelipatan produk yang sama</li>
<li>Diskon tidak berlaku untuk pembelian yang dibatalkan customer</li>
<li>Diskon tidak dapat diuangkan</li>
<li>Quota diskon berlaku first come first server ( siapa yang paling cepat )</li>
<li>S & K sewaktu-waktu dapat di ubah tanpa pemberitahuan</li>
</ol>
</Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                align="left"
                sx={{ mt: "25px" }}
              >
                <Box
                  sx={{
                    mb: "10px",
                    bgcolor: "#9D28B1",
                    p: "5px",
                    color: "#fff",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    letterSpacing: "1px",
                  }}
                >
                  BUAT PERMINTAAN
                </Box>
                <Box sx={{ p: 2 }}>
                  <Grid
                    container
                    spacing={2}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Nama Lengkap
                      </Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label="Nama Lengkap"
                        variant="filled"
                        name="nama"
                        value={Webdata.nama}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Nomor Whatsapp
                      </Box>
                      <TextField
                        fullWidth
                        required
                        size="small"
                        label="Nomor Whatsapp"
                        variant="filled"
                        name="whatsapp"
                        value={Webdata.whatsapp}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Alamat
                      </Box>
                      <TextField
                        fullWidth
                        multiline
                        required
                        label="Alamat"
                        variant="filled"
                        rows={4}
                        name="alamat"
                        value={Webdata.alamat}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Permintaan
                      </Box>
                      <TextField
                        fullWidth
                        multiline
                        required
                        label="Jelaskan kebutuhan anda : warna, ukuran dll
"
                        variant="filled"
                        rows={4}
                        name="permintaan"
                        value={Webdata.permintaan}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Referensi Produk (opsional)
                      </Box>
                      <TextField
                        fullWidth
                        type="file"
                        size="small"
                        variant="filled"
                        name="fotoreferensi"
                        onChange={handleChangefoto}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Quantity
                      </Box>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        label="Qty"
                        variant="filled"
                        name="qty"
                        value={Webdata.qty}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={async () => await handleSubmit(Webdata)}
                      >
                        Kirim Permintaan
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 5 }} />
          </Box>

          <ProductList page={"PDP"} />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
