// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8tvbtuRT_LP7CaVjMbBkAQ0sfzky00Pg",
  authDomain: "garapin-f35ef.firebaseapp.com",
  databaseURL: "https://garapin-f35ef.firebaseio.com",
  projectId: "garapin-f35ef",
  storageBucket: "garapin-f35ef.appspot.com",
  messagingSenderId: "607837640605",
  appId: "1:607837640605:web:f0649f207d151550460174",
  measurementId: "G-006FP6N0HS",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logProduk = (page, name) => {
  logEvent(analytics, `${page}-Views`, { name: name });
};

export const logInquiry = (name) => {
  logEvent(analytics, `PDP-Inquiry`, { name: name });
};

// export const logU = (name) => {
//   logEvent(analytics, `PDP-Inquiry`, { name: name });
// };

export const logError = () => {
  logEvent(analytics, `Error_input`, { name: "Banner_Popup" });
};

export const logSuccess = () => {
  logEvent(analytics, `PDP-Inquiry-success`, { name: "Banner_Popup" });
};
