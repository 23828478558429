import * as React from "react";
import Footer from "../pages/footer";
import { useState } from "react";
import "@fontsource/roboto-mono";
import "@fontsource/bebas-neue";
import "@fontsource/roboto";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Slider from "react-slick";
import WebIcon from "../images/icon.png";
import ProductList from "../pages/productList";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Divider from "@mui/material/Divider";
import StarIcon from "@mui/icons-material/Star";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Img1 from "../images/product/slingbag/default.jpg";
import Img2 from "../images/product/slingbag/Farida slingbag.jpg";
import Img3 from "../images/product/slingbag/Gerbera slingbag.jpg";
import Img4 from "../images/product/slingbag/Renina slingbag (1).jpg";
import Img5 from "../images/product/slingbag/Renina slingbag.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { handleSubmit } from "../utils";
export default function WelcomePage() {
  const settings = {
    dots: false,
    vertical: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  const [Webdata, setWebdata] = useState({
    nama: "",
    whatsapp: "",
    alamat: "",
    permintaan: "",
    fotoreferensi: "",
    qty: "",
    produk: "Sling Bag Custom",
  });

  const handleChange = (event) => {
    setWebdata({
      ...Webdata,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangefoto = (event) => {
    setWebdata({
      ...Webdata,
      [event.target.name]: event.target.files[0],
    });
  };
  return (
    <>
      <Box sx={{ bgcolor: "rgba(255,255,255,0.6)" }}>
        <Box align="center">
          <br />
          <br />
          <Link href="/">
            <Box
              component="img"
              sx={{
                maxWidth: 400,
              }}
              alt="GarapIn x Rerejuna Craft"
              title="GarapIn x Rerejuna Craft"
              src={WebIcon}
            />
          </Link>
          <Box
            sx={{
              m: "0 auto",
              maxWidth: "800px",
              mt: 4,
            }}
          >
            <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={6} lg={6} align="left">
                <Box className="immages slider ima2">
                  <Slider {...settings}>
                    <div>
                      <img
                        src={Img1}
                        alt="GarapIn x Rerejuna Craft"
                        title="GarapIn x Rerejuna Craft"
                      />
                    </div>
                    <div>
                      <img
                        src={Img2}
                        alt="GarapIn x Rerejuna Craft"
                        title="GarapIn x Rerejuna Craft"
                      />
                    </div>
                    <div>
                      <img
                        src={Img3}
                        alt="GarapIn x Rerejuna Craft"
                        title="GarapIn x Rerejuna Craft"
                      />
                    </div>
                    <div>
                      <img
                        src={Img4}
                        alt="GarapIn x Rerejuna Craft"
                        title="GarapIn x Rerejuna Craft"
                      />
                    </div>
                    <div>
                      <img
                        src={Img5}
                        alt="GarapIn x Rerejuna Craft"
                        title="GarapIn x Rerejuna Craft"
                      />
                    </div>
                  </Slider>
                </Box>
                <Box className="slider-nav">
                  <div>
                    <img
                      src={Img1}
                      alt="GarapIn x Rerejuna Craft"
                      title="GarapIn x Rerejuna Craft"
                    />
                  </div>
                  <div>
                    <img
                      src={Img2}
                      alt="GarapIn x Rerejuna Craft"
                      title="GarapIn x Rerejuna Craft"
                    />
                  </div>
                  <div>
                    <img
                      src={Img3}
                      alt="GarapIn x Rerejuna Craft"
                      title="GarapIn x Rerejuna Craft"
                    />
                  </div>
                  <div>
                    <img
                      src={Img4}
                      alt="GarapIn x Rerejuna Craft"
                      title="GarapIn x Rerejuna Craft"
                    />
                  </div>
                  <div>
                    <img
                      src={Img5}
                      alt="GarapIn x Rerejuna Craft"
                      title="GarapIn x Rerejuna Craft"
                    />
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                align="left"
                sx={{ mb: 2 }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography variant="h4" sx={{ fontSize: "1.5rem" }}>
                    <b>Sling Bag Custom</b>
                  </Typography>
                  <Box
                    className="pricing2"
                    sx={{ fontSize: "20px", mt: "5px", fontFamily: "Roboto" }}
                  >
                    Rp 175.000 - 350.000
                  </Box>
                  <Box className="rating" sx={{ mt: 1 }}>
                    <StarIcon sx={{ color: "#DFCB00" }} />
                    <StarIcon sx={{ color: "#DFCB00" }} />
                    <StarIcon sx={{ color: "#DFCB00" }} />
                    <StarIcon sx={{ color: "#DFCB00" }} />
                    <StarIcon sx={{ color: "#D0D0D0" }} />
                  </Box>
                  <Box
                    className="order"
                    sx={{ mt: "7px", fontFamily: "Roboto" }}
                  >
                    <ShoppingCartIcon /> 1,923x terjual
                  </Box>
                  <Box
                    className="order"
                    sx={{ mt: "7px", fontFamily: "Roboto" }}
                  >
                    <ShoppingBasketIcon /> Minimal pemesanan 1 pcs
                  </Box>
                  <Box
                    sx={{ mt: "25px", fontSize: "16px", fontFamily: "Roboto" }}
                  >
                    Memproduksi tas jenis slingbag dengan kreatifitas tinggi dan
                    dengan berbagai macam bahan yang populer.
                    <br />
                    Spesialnya adalah bisa berbahan dasar kain khas nusantara
                    khususnya kain khas daerah <b>KALIMANTAN BARAT</b>.
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                align="left"
                sx={{ mt: "25px" }}
              >
                <Box
                  sx={{
                    mb: "10px",
                    bgcolor: "#9D28B1",
                    p: "5px",
                    color: "#fff",
                    textAlign: "center",
                    fontFamily: "Roboto",
                    letterSpacing: "1px",
                  }}
                >
                  BUAT PERMINTAAN
                </Box>
                <Box sx={{ p: 2 }}>
                  <Grid
                    container
                    spacing={2}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Nama Lengkap
                      </Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        label="Nama Lengkap"
                        variant="filled"
                        name="nama"
                        value={Webdata.nama}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Nomor Whatsapp
                      </Box>
                      <TextField
                        fullWidth
                        required
                        size="small"
                        label="Nomor Whatsapp"
                        variant="filled"
                        name="whatsapp"
                        value={Webdata.whatsapp}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Alamat
                      </Box>
                      <TextField
                        fullWidth
                        multiline
                        required
                        label="Alamat"
                        variant="filled"
                        rows={4}
                        name="alamat"
                        value={Webdata.alamat}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Permintaan
                      </Box>
                      <TextField
                        fullWidth
                        multiline
                        required
                        label="Permintaan"
                        variant="filled"
                        rows={4}
                        name="permintaan"
                        value={Webdata.permintaan}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Referensi Produk (opsional)
                      </Box>
                      <TextField
                        fullWidth
                        type="file"
                        size="small"
                        variant="filled"
                        name="fotoreferensi"
                        onChange={handleChangefoto}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                        }}
                      >
                        Quantity
                      </Box>
                      <TextField
                        fullWidth
                        type="number"
                        size="small"
                        label="Qty"
                        variant="filled"
                        name="qty"
                        value={Webdata.qty}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={async () => await handleSubmit(Webdata)}
                      >
                        Kirim Permintaan
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 5 }} />
          </Box>

          <ProductList page={"PDP"} />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
