import * as React from "react";
import Footer from "../pages/footer";
import "@fontsource/roboto-mono";
import "@fontsource/bebas-neue";
import "@fontsource/roboto";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import WebIcon from "../images/icon.png";

import SliderSatu from "../images/Asset 18.png";

import BannerSatu from "../images/banner1.jpg";
import BannerDua from "../images/banner2.jpg";
import BannerTiga from "../images/banner3.jpg";
import BannerEmpat from "../images/banner4.jpg";

import ProductList from "../pages/productList";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function WelcomePage() {
  const settings = {
    dots: true,
    vertical: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <>
      <Box sx={{ bgcolor: "rgba(255,255,255,0.6)" }}>
        <Box align="center">
          <br />
          <br />
          <Box
            component="img"
            sx={{
              maxWidth: 400,
            }}
            alt="GarapIn x Rerejuna Craft"
            title="GarapIn x Rerejuna Craft"
            src={WebIcon}
          />
          <div className="BannerSlider">
            <Slider {...settings}>
              <div>
                <img
                  src={SliderSatu}
                  alt="GarapIn x Rerejuna Craft"
                  title="GarapIn x Rerejuna Craft"
                />
              </div>
            </Slider>
          </div>
          <Typography variant="h6" className="subTitle" sx={{ mt: 10 }}>
            mempersembahkan
          </Typography>
          <Typography variant="span" className="headTitle">
            Produk Eksklusif
            <br />
            dengan Sentuhan Kearifan Lokal
          </Typography>
          <Divider sx={{ maxWidth: "400px" }} />
          <Box
            sx={{
              m: "0 auto",
              maxWidth: "600px",
              fontSize: "17px",
              fontFamily: "Roboto",
              mt: 3,
            }}
          >
            Custom berbagai produk tas menggunakan kain otentik khas Indonesia.
            <br />
            Ekspresikan diri dengan ide yang kamu miliki, Rerejuna siap
            berkolaborasi dan mewujudkan tas impianmu!
          </Box>
          <Box sx={{ m: "0 auto", maxWidth: "800px", mt: 7 }}>
            <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={6} align="center">
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                  }}
                  alt="Produk Custom Yang Ekslusif dan Tidak Pasaran"
                  title="Produk Custom Yang Ekslusif dan Tidak Pasaran"
                  src={BannerSatu}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} align="center">
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                  }}
                  alt="Bebas Mengekspresikan Ide Mu"
                  title="Bebas Mengekspresikan Ide Mu"
                  src={BannerDua}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} align="center">
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                  }}
                  alt="Menggunakan Kain Otentik Khas Indonesia"
                  title="Menggunakan Kain Otentik Khas Indonesia"
                  src={BannerTiga}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} align="center">
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                  }}
                  alt="Produk Handmade Yang Terjamin Kualitasnya"
                  title="Produk Handmade Yang Terjamin Kualitasnya"
                  src={BannerEmpat}
                />
              </Grid>
            </Grid>
          </Box>
          <ProductList page={"Home"} />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
