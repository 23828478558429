import React from 'react';
import ReactDOM from 'react-dom/client';
import "@fontsource/roboto-mono"
import "@fontsource/bebas-neue"
import "@fontsource/roboto"
import './css/style.css';
import Dashboard from './pages/dashboard';
import ProductBackpack from './pages/product_backpack';
import ProductDompet from './pages/product_dompet';
import ProductPouch from './pages/product_pouch';
import ProductSlingbag from './pages/product_slingbag';
import ProductTotebag from './pages/product_totebag';
import ProductWeistbag from './pages/product_waistbag';
import ProductFoldoverbackpack from './pages/product_foldoverbackpack';
import Producthbackpack from './pages/product_hbackpack';
import Productfannybag from './pages/product_fannybag';
import Productantitheftbackpak from './pages/product_antitheftbackpak';
import Productshanellobackpack from './pages/product_shanellobackpack';
import Productdieanne from './pages/product_dianne';
import Productkafa from './pages/product_kafa';
import Producthybird from './pages/product_hybridbackpack';
import Productmarble from './pages/product_marblebackpack';
import Productdompetetnik from './pages/product_dompetetnik';
import Productdompetmotif from './pages/product_dompetmotif';
import Productpouchunik from './pages/product_pouchunik';
import Productdoublerits from './pages/product_doublerits';
import Productsmartphoneholder from './pages/product_smartphoneholder';
import Productclematispouch from './pages/product_clematispouch';
import Productlilybag from './pages/product_lilybag';
import Producttissuepouch from './pages/product_tissuepouch';
import Productreikobag from './pages/product_reikobag';
import Productlinsybag from './pages/product_linsybag';
import Productgarberabag from './pages/product_garberabag';
import Productreninabag from './pages/product_reninabag';
import Productfaridabag from './pages/product_faridabag';
import Productconniebag from './pages/product_conniebag';
import Producthammockbag from './pages/product_hammockbag';
import Productquininabag from './pages/product_quininabag';
import Productalineabag from './pages/product_alineabag';
import Productbiggerbag from './pages/product_biggerbag';
import Productkiabag from './pages/product_kiabag';
import Productresibag from './pages/product_resibag';
import Productdoctorbag from './pages/product_doctorbag';
import Productdrowstringbag from './pages/product_drowstringbag';
import Productjunerabag from './pages/product_junerabag';
import Productsoniabag from './pages/product_soniabag';
import Productlaptopbag from './pages/product_laptopbag';
import Productrummybag from './pages/product_rummybag';
import Producteasybag from './pages/product_easybag';
import Productorigamibag from './pages/product_origamibag';
//import Product from './pages/product_';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import { theme } from "./theme";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Dashboard />}></Route>
          <Route exact path="/easybag" element={<Producteasybag />}></Route>
          <Route exact path="/origamibag" element={<Productorigamibag />}></Route>
          <Route exact path="/soniabag" element={<Productsoniabag />}></Route>
          <Route exact path="/laptopbag" element={<Productlaptopbag />}></Route>
          <Route exact path="/rummybag" element={<Productrummybag />}></Route>
          <Route exact path="/doctorbag" element={<Productdoctorbag />}></Route>
          <Route exact path="/drowstringbag" element={<Productdrowstringbag />}></Route>
          <Route exact path="/junerabag" element={<Productjunerabag />}></Route>
          <Route exact path="/biggerbag" element={<Productbiggerbag />}></Route>
          <Route exact path="/kiabag" element={<Productkiabag />}></Route>
          <Route exact path="/resibag" element={<Productresibag />}></Route>
          <Route exact path="/hammockbag" element={<Producthammockbag />}></Route>
          <Route exact path="/quininabag" element={<Productquininabag />}></Route>
          <Route exact path="/alineabag" element={<Productalineabag />}></Route>
          <Route exact path="/faridabag" element={<Productfaridabag />}></Route>
          <Route exact path="/conniebag" element={<Productconniebag />}></Route>
          <Route exact path="/garberabag" element={<Productgarberabag />}></Route>
          <Route exact path="/reninabag" element={<Productreninabag />}></Route>
          <Route exact path="/reikobag" element={<Productreikobag />}></Route>
          <Route exact path="/linsybag" element={<Productlinsybag />}></Route>
          <Route exact path="/lilybag" element={<Productlilybag />}></Route>
          <Route exact path="/tissuepouch" element={<Producttissuepouch />}></Route>
          <Route exact path="/smartphoneholder" element={<Productsmartphoneholder />}></Route>
          <Route exact path="/clematispouch" element={<Productclematispouch />}></Route>
          <Route exact path="/pouchunik" element={<Productpouchunik />}></Route>
          <Route exact path="/doublerits" element={<Productdoublerits />}></Route>
          <Route exact path="/dompetetnik" element={<Productdompetetnik />}></Route>
          <Route exact path="/dompetmotif" element={<Productdompetmotif />}></Route>
          <Route exact path="/hybridbackpack" element={<Producthybird />}></Route>
          <Route exact path="/marblebackpack" element={<Productmarble />}></Route>
          <Route exact path="/backpack" element={<ProductBackpack />}></Route>
          <Route exact path="/dompet" element={<ProductDompet />}></Route>
          <Route exact path="/pouch" element={<ProductPouch />}></Route>
          <Route exact path="/slingbag" element={<ProductSlingbag />}></Route>
          <Route exact path="/totebag" element={<ProductTotebag />}></Route>
          <Route exact path="/waistbag" element={<ProductWeistbag />}></Route>
          <Route exact path="/foldoverbackpack" element={<ProductFoldoverbackpack />}></Route>
          <Route exact path="/hbackpack" element={<Producthbackpack />}></Route>
          <Route exact path="/shanellobackpack" element={<Productshanellobackpack />}></Route>
          <Route exact path="/fannybag" element={<Productfannybag />}></Route>
          <Route exact path="/antitheftbackpak" element={<Productantitheftbackpak />}></Route>
          <Route exact path="/dianne" element={<Productdieanne />}></Route>
          <Route exact path="/kafa" element={<Productkafa />}></Route>


        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);

