import * as React from "react";
import "@fontsource/roboto-mono"
import "@fontsource/bebas-neue"
import "@fontsource/roboto"
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import GooglePlay from '../images/google-play-button.png';
import AppStore from '../images/apple-app-store-button.png';
export default function FooterPage() {
    return (
        <>
            <Box sx={{
                bgcolor:"#9D28B1",
                p:3,
                mt:5,
            }}>
                <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid item xs={12} sm={12} md={3} lg={3} align="center"></Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} align="center">
                        <Link href="https://play.google.com/store/apps/details?id=com.garapin.apps">
                            <Box
                                component="img"
                                sx={{
                                maxWidth: 200,
                                }}
                                alt="Download GarapIn"
                                title="Download GarapIn"
                                src={GooglePlay}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} align="center">
                    <Link href="https://apps.apple.com/id/app/garapin/id1557183866">
                            <Box
                                component="img"
                                sx={{
                                maxWidth: 200,
                                }}
                                alt="Download GarapIn"
                                title="Download GarapIn"
                                src={AppStore}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} align="center"></Grid>
                </Grid>
            </Box>
        </>
    )
}