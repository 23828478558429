import axios from "axios";
import Swal from "sweetalert";
import { logError, logInquiry, logSuccess } from "./log";

const APIURL =
  "https://asia-southeast2-garapin-f35ef.cloudfunctions.net/rerejuna";

export const handleSubmit = async (Webdata) => {
  logInquiry(Webdata.produk);
  if (Webdata.nama === "") {
    Swal("Kesalahan!", "Mohon masukan nama kamu", "info");
    logError();
  } else if (Webdata.whatsapp === "") {
    Swal(
      "Kesalahan!",
      "Mohon masukan nomor whatsapp, agar tim kami bisa menghubungi kamu",
      "info"
    );
    logError();
  } else if (Webdata.alamat === "") {
    Swal("Kesalahan!", "Mohon masukan alamat kamu!", "info");
    logError();
  } else if (Webdata.permintaan === "") {
    Swal("Kesalahan!", "Mohon masukan rincian permintaan kamu!", "info");
    logError();
  } else if (Webdata.qty < 1) {
    Swal("Kesalahan!", "Mohon masukan quantity dengan benar", "info");
    logError();
  } else {
    const PermintaanUser = new FormData();
    PermintaanUser.append("name", Webdata.nama);
    PermintaanUser.append("phone_number", Webdata.whatsapp);
    PermintaanUser.append("address", Webdata.alamat);
    PermintaanUser.append("request", Webdata.permintaan);
    if (Webdata.fotoreferensi === "") {
    } else {
      PermintaanUser.append("file", Webdata.fotoreferensi);
    }
    PermintaanUser.append("qty", Webdata.qty);
    PermintaanUser.append("produk", Webdata.produk);
    axios
      .post(APIURL, PermintaanUser, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data;`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response.data.name) {
          Swal(
            "Berhasil!",
            "Permintaan berhasil dibuat, Kami akan segera menghubungi kamu",
            "success"
          );
          logSuccess();
        } else {
          Swal("Kesalahan!", "Kami gagal membuat permintaan kamu", "error");
          logError();
        }
      })
      .catch((error) => {
        Swal(
          "Error!",
          "Gagal melakukan permintaan, silahkan coba lagi.",
          "error"
        );
        logError();
      });
  }
};
