import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
    color: "#3A3A3A",
    fontSize: "15px",
    h1: {
      fontSize: "80px",
      fontWeight: 800,
      lineHeight: "80px",
    },
  },
  box:{
    fontFamily: "Roboto",
    color: "#3A3A3A",
  },
  Media: {
    width: '100%',
    objectFit: 'cover'
  },
  palette: {
    primary: {
      main: "#9D28B1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FADEFF",
    },
    info: {
      main: "#00BAF4",
      contrastText: "#fff",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            borderRadius: "10px",
            paddingBlock: "10px",
          },
        },
      ],
    },
  },
});
